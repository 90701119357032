<template>
  <v-card>
    <TechnologicalDevelopmentsCategoryHeader @openToAdd="openDialog" @search="changeSearch"></TechnologicalDevelopmentsCategoryHeader>
    <DatatableTechnologicalDevelopmentsCategory
      :technologicalCategory="technologicalCategory"
      :loading="loading"
      :search="search"
      @edit="openEdit"
      @remove="openRemove"
    ></DatatableTechnologicalDevelopmentsCategory>
    <WDialog width="500" :dialog="dialog" :closeDialog="closeDialog" :title="titleDialog" :subtitle="subtitleDialog">
      <template v-slot:form-dialog>
        <FormTechnologicalDevelopmentsCategory
          v-if="dialog"
          :loading="loading"
          :technologicalCategoryEdited="technologicalCategoryEdited"
          @beforeUpdate="updateTechnologicalCategory"
          @beforeCreate="createTechnologicalCategory"
        ></FormTechnologicalDevelopmentsCategory>
      </template>
    </WDialog>
  </v-card>
</template>

<script>
import DatatableTechnologicalDevelopmentsCategory from './DatatableTechnologicalDevelopmentsCategory.vue'
import FormTechnologicalDevelopmentsCategory from '@/components/forms/FormTechnologicalDevelopmentsCategory.vue'
import TechnologicalDevelopmentsCategoryHeader from './TechnologicalDevelopmentsCategoryHeader.vue'
import WDialog from '@/components/dialogs/WDialog.vue'
import useTechnologicalCategory from '@/composables/useTechnologicalCategory'

export default {
  components: {
    DatatableTechnologicalDevelopmentsCategory,
    FormTechnologicalDevelopmentsCategory,
    TechnologicalDevelopmentsCategoryHeader,
    WDialog,
  },
  setup() {
    // Composition API
    const {
      dialog,
      technologicalCategoryEdited,
      technologicalCategory,
      search,
      loading,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      changeSearch,
      openDialog,
      openEdit,
      openRemove,
      closeDialog,
      // crud
      createTechnologicalCategory,
      updateTechnologicalCategory,
    } = useTechnologicalCategory()

    return {
      dialog,
      technologicalCategoryEdited,
      technologicalCategory,
      search,
      loading,
      // computed
      titleDialog,
      subtitleDialog,
      // methods
      changeSearch,
      openDialog,
      openEdit,
      openRemove,
      closeDialog,
      // crud
      createTechnologicalCategory,
      updateTechnologicalCategory,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
