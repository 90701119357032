import { ref, computed } from '@vue/composition-api';
import store from '@/store/index';

const useTechnologicalCategory = () => {
  // base data
  const dialog = ref(false);
  const technologicalCategoryEdited = ref(null);
  const technologicalCategory = ref([]);
  const search = ref("");
  const loading = ref(false);

  // computed properties
  const titleDialog = computed(() => technologicalCategoryEdited.value ? "Editar Promocion de Lotes" : "Agregar Categoria de desarrollo tecnologico");
  const subtitleDialog = computed(() =>
    technologicalCategoryEdited.value ?
      "Rellene los campos correctamente para modificar la categoria" :
      "Rellene los campos correctamente para agregar una nueva categoria"
  );

  // methods
  const openDialog = () => {
    dialog.value = true;
  }
  const openEdit = (technologicalCategory) => {
    dialog.value = true;
    technologicalCategoryEdited.value = { ...technologicalCategory };
  }
  const closeDialog = () => {
    dialog.value = false;
    technologicalCategoryEdited.value = null;
  }

  // crud
  const getTechnologicalCategory = async () => {
    loading.value = true;
    const { data, status } = await store.dispatch('fetchTechnologicalCategory');
    loading.value = false;
    if (status != 200) return;
    technologicalCategory.value = data;
    // console.log(data);
  }

  const createTechnologicalCategory = async (technologicalCategory) => {
    loading.value = true;
    const { data, status } = await store.dispatch("createTechnologicalCategory", technologicalCategory)
    loading.value = false;
    if (status != 200 && status != 201) return;
    await getTechnologicalCategory();
    closeDialog();
  }

  const updateTechnologicalCategory = async (technologicalCategory) => {
    loading.value = true;
    const { data, status } = await store.dispatch("updateTechnologicalCategory", technologicalCategory)
    loading.value = false;
    if (status != 200 && status != 201) return;
    await getTechnologicalCategory();
    closeDialog();
  }

  const removeTechnologicalCategory = async (user) => {
    const val = await store.dispatch("generateConfirmDialog", {});
    if (!val) return;
    const { data, status } = await store.dispatch("removeTechnologicalCategory", user)
    if (status != 200 && status != 201 && status != 204) return;
    await getTechnologicalCategory();
  }


  getTechnologicalCategory();

  return {
    technologicalCategory,
    loading,
    dialog,
    search,
    technologicalCategoryEdited,
    // computed
    titleDialog,
    subtitleDialog,
    // methods
    changeSearch(ev) {
      search.value = ev
    },
    openDialog() {
      dialog.value = true;
    },
    openEdit(technologicalCategory) {
      dialog.value = true;
      technologicalCategoryEdited.value = { ...technologicalCategory };
    },
    openDialog,
    openEdit,
    closeDialog,
    // crud
    openRemove: removeTechnologicalCategory,
    createTechnologicalCategory,
    updateTechnologicalCategory,
  };
};

export default useTechnologicalCategory;
