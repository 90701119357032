
<template>
  <FormTemplate>
    <template v-slot:form-body>
      <v-form ref="formTechnologicalCategory" @submit.prevent="saveForm">
        <v-row>
          <v-col cols="12" md="12">
            <label for="category">Tipo de categoria</label>
          </v-col>

          <v-col cols="12" md="12" class="pb-0">
            <v-text-field
              id="category"
              v-model="technologicalCategory.name_category"
              outlined
              dense
              placeholder="Categoría"
              :rules="required"
            ></v-text-field>
          </v-col>

          <v-col offset-md="3" cols="9">
            <v-btn type="submit" :disabled="loading" :loading="loading" color="primary"> Guardar </v-btn>
            <v-btn type="reset" @click="reset" class="mx-2" outlined> Limpiar </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </FormTemplate>
</template>

<script>
import FormTemplate from '@/components/forms/FormTemplate.vue'
import { loadAPIImage } from '@/utils/images'

export default {
  components: {
    FormTemplate,
  },
  props: {
    technologicalCategoryEdited: Object,
    loading: Boolean,
  },
  data() {
    return {
      required: [v => !!v || 'Requerido'],
      url: '',
      image: null,
      technologicalCategory: this.technologicalCategoryEdited ? { ...this.technologicalCategoryEdited } : {},
    }
  },
  methods: {
    validateForm() {
      return this.$refs.formTechnologicalCategory.validate()
    },
    reset() {
      this.$refs.formTechnologicalCategory.resetValidation()
    },
    saveForm() {
      if (!this.validateForm()) return
      if (this.technologicalCategoryEdited) {
        let DTO = { ...this.technologicalCategory }
        // // console.log('actualizar', DTO)
        this.$emit('beforeUpdate', DTO)
      } else {
        const DTO = { ...this.technologicalCategory }
        // // console.log('crear', DTO)
        this.$emit('beforeCreate', DTO)
      }
    },
    loadImage(src) {
      return loadAPIImage(src)
    },
    onFileChange(e) {
      if (!e) return
      this.url = URL.createObjectURL(e)
    },
  },
}
</script>

<style lang="scss" scoped>
img {
  max-width: 450px;
  max-height: 450px;
}

.center-sub-items {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section {
  margin: 0 auto;
  max-width: 900px;
}
</style>
